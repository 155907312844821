<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          <span class="headline">Add Receipt Data</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="stores"
                  item-text="_id"
                  item-value="_id"
                  clearable
                  dense
                  outlined
                  label="Store name"
                  v-model="receipt.storeName"
                >
                  <template v-slot:no-data>
                    <v-row align="center" justify="space-between" class="pa-2">
                      <v-col cols="12">
                        <v-text-field
                          v-model="newRecordValue"
                          label="Store not found? Add new store"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          @click="addNewRecord('stores')"
                          small
                          elevation="2"
                          color="primary"
                          :disabled="!newRecordValue.trim()"
                        >
                          Add Store
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="receipt.storelocation"
                  label="Store Location"
                  outlined
                  dense
                  required
                  persistent-hint
                  hint="e.g Mid Valley, 1 Utama"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="receipt.storeType"
                  :items="storeType"
                  item-text="text"
                  item-value="text"
                  label="Store Type"
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="receipt.totalAmount"
                  label="Total Amount"
                  type="number"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="receipt.tax"
                  label="Tax"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-menu
                  ref="purchaseDateMenu"
                  v-model="purchaseDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="purchaseDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="receipt.purchaseDate"
                      label="Purchase Date"
                      prepend-icon="event"
                      readonly
                      outlined
                      dense
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    color="primary"
                    no-title
                    scrollable
                    v-model="receipt.purchaseDate"
                  >
                    <v-spacer></v-spacer>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.purchaseDateMenu.save(Date(purchaseDate))"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="receipt.paymentType"
                  :items="paymentMethod"
                  item-text="text"
                  item-value="text"
                  label="Payment Method"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="receipt.channel"
                  :items="channel"
                  item-text="text"
                  item-value="text"
                  label="Channel"
                  persiste
                  dense
                  outlined
                  persistent-hint
                  hint="Purchase Online or Offline"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Submit Button -->
          </v-form>
        </v-card-text>
        <div>
          <v-btn class="mb-2 ml-2" @click="addProduct" color="primary" tonal>
            Add Product
          </v-btn>
        </div>
      </v-card>
    </v-container>
    <div class="mt-5">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(product, index) in receipt.products"
          :key="index"
        >
          <v-expansion-panel-header>
            <h4>Product {{ index + 1 }}</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="brands"
                  item-text="_id"
                  item-value="_id"
                  clearable
                  dense
                  outlined
                  label="Brand name"
                  v-model="product.brand"
                >
                  <template v-slot:no-data>
                    <v-row align="center" justify="space-between" class="pa-2">
                      <v-col cols="12">
                        <v-text-field
                          v-model="newRecordValue"
                          label="Store not found? Add new store"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          @click="addNewRecord('brands')"
                          small
                          elevation="2"
                          color="primary"
                          :disabled="!newRecordValue.trim()"
                        >
                          Add Store
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="product.flavour"
                  label="Flavour"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="product.packSize"
                  label="Pack Size"
                  type="number"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="product.quantity"
                  label="Quantity"
                  type="number"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="product.price"
                  label="Price"
                  type="number"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="product.category"
                  :items="category"
                  item-text="text"
                  item-value="text"
                  label="Category"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-btn color="red" @click="removeProduct(index)" outlined>
              Remove Product
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-4 mb-4"
            @click="submit"
            :disabled="!valid"
            v-bind="attrs"
            v-on="on"
          >
            Save all data
          </v-btn>
        </template>
        <span>Make sure to fill in all the product data first</span>
      </v-tooltip>
    </div>
    <v-dialog v-model="successDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>

        <v-card-text> {{ dialogMessage }} </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeTab">Close Tab</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";
const service = new RestResource();
export default {
  data() {
    return {
      valid: false,
      successDialog: false,
      purchaseDateMenu: false,
      purchaseDate: null,
      paymentMethod: ["Cash", "Card"],
      channel: ["Online", "Offline"],
      newRecordValue: "",
      staticData: ["stores", "brands"],
      stores: [],
      brands: [],
      category: [
        "Beverages",
        "Food",
        "Health Care",
        "Personal Care",
        "Home Care",
        "Durable/Appliance/Apparel",
        "Media/Social",
        "Home Improvement",
        "Tobaco/Cigarettes",
        "Vapes/E-Cigarettes",
        "Pet Care",
        "Retail/Apparel",
        "Travel and Leisure",
        "Insurance/Financial",
        "Hardware Appliances",
        "Others",
      ],
      storeType: [
        "Super Market",
        "Mini Market",
        "Local Market",
        "Food Place",
        "Gas Station",
        "Restaurant",
        "Local Restaurant",
        "Pharmacy",
      ],
      receipt: {
        storeName: "",
        totalAmount: null,
        tax: null,
        purchaseDate: undefined,
        products: [],
      },
      dialogTitle: "",
      dialogMessage: "",
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      service.getDataForReceipts({ key: this.staticData }).then((res) => {
        res.data.forEach((rd) => {
          if (rd.key === "stores") {
            this.stores = rd.value;
          } else if (rd.key === "brands") {
            this.brands = rd.value;
          }
        });
      });
    },

    addProduct() {
      this.receipt.products.push({});
    },

    removeProduct(index) {
      this.receipt.products.splice(index, 1);
    },

    async addNewRecord(type) {
      if (!this.newRecordValue.trim()) return;

      try {
        await service.updateRecordInConfig({
          type,
          value: this.newRecordValue,
        });

        alert(`${this.newRecordValue} has been added to ${type}.`);

        this.getData();

        this.newRecordValue = "";
      } catch (error) {
        console.error(`Error adding ${type}:`, error);
      }
    },

    closeTab() {
      window.close();
    },

    submit() {
      this.$setLoader();

      try {
        if (!this.receipt.storeName || !this.receipt.products.length) {
          alert(
            "Please fill in all required fields and add at least one product."
          );
          this.$disableLoader();
          return;
        }

        const finalData = {
          userId: this.$route.params.userId,
          receiptId: this.$route.params.receiptId,
          storeName: this.receipt.storeName,
          totalAmount: this.receipt.totalAmount,
          tax: this.receipt.tax,
          paymentType: this.receipt.paymentType,
          purchaseDate: this.receipt.purchaseDate,
          storeLocation: this.receipt.storeLocation,
          channel: this.receipt.channel,
          storeType: this.receipt.storeType,
          products: this.receipt.products.map(
            ({ brand, flavour, packSize, quantity, price, category }) => ({
              brand,
              flavour,
              packSize,
              quantity,
              price,
              category,
            })
          ),
        };

        service
          .saveReceiptData(finalData)
          .then(() => {
            this.successDialog = true;
            this.dialogTitle = "Success";
            this.dialogMessage = "Data Added Successfully";
          })
          .catch((error) => {
            console.error("Error saving receipt data:", error);

            if (error.response) {
              this.dialogTitle = "Error";
              this.dialogMessage = `Error: ${
                error.response.data.message ||
                "An error occurred while saving the data."
              }`;
            } else if (error.request) {
              this.dialogTitle = "Error";
              this.dialogMessage =
                "No response from the server. Please try again later.";
            } else {
              this.dialogTitle = "Error";
              this.dialogMessage = `Request error: ${error.message}`;
            }
            this.successDialog = true;
          })
          .finally(() => {
            this.$disableLoader();
          });
      } catch (error) {
        console.error("Unexpected error:", error);
        this.dialogTitle = "Error";
        this.dialogMessage = `An unexpected error occurred: ${error.message}`;
        this.successDialog = true;
        this.$disableLoader();
      }
    },
  },
};
</script>
